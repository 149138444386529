$(document).ready(function () {

    if ($('.js-datepicker').length) {
        datepicker();
    };

    if ($('.js-list-item').length) {
        verticalslider($('.js-list-item'));
    }

    if ($('.js-language-change').length) {
        languageSelector();
    }

    if ($('.slider').length) {
        slider();
    }

    if ($('.js-verticalslider').length) {
        verticalslider($('.js-verticalslider'));
    }

    if ($('.js-form').length) {
        formMessage();
    }

    if ($('.js-row').length) {
        loadRow();
    }

    if ($('.js-sly').length) {
        verticalSliderTwo();
    }

    if ($('.slider-for').length) {
        sliderone();
    }
    if ($('.slider-nav').length) {
        slidertwo();
    }
    if ($('.js-scroll').length) {
        offsettop();
    }
    if ($('.js-slider').length) {
        sliderthree();
    }
    if ($('.js-slider-two').length) {
        sliderfour();
    }
    if ($('.js-sprite').length) {
        spritespin();
    }
    if ($('#js-sticky').length) {
        stickyHeader();
    }
    if ($('.js-video').length) {
        video();
    }
    if ($('.sidebar').length) {
        stickySidebar();
    }
    if ($('.js-timeline').length) {
        timeline();
    }

    if ($('.js-start').length) {
        animateTorus();
    }

    if ($('.js-tooltip').length) {
        tooltip();
    }

    function animateTorus() {
        $('.js-start').appear();
        $(document.body).on('appear', '.js-start:not(.start)', function () {
            $(this).addClass('start');
        });
    }

    $(document).ajaxStart(function () {
        $(".loader").show();
        // $('.js-site').css('opacity', 0);
    });

    $(document).ajaxComplete(function () {
        $(".loader").hide();
        // $('.js-site').css('opacity', 1);
    });

    $(document).keydown(function (evtOne) {
        if (evtOne.keyCode == 27) {
            $('.js-burger').removeClass('active');
            $('.js-main-header').removeClass('active');
            $('body').removeClass('body-active');
            $('.js-navigation').fadeOut(350);
            $('.js-nav-list').removeClass('is-open');
        }
    });

    function sliderone() {
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
    }

    function slidertwo() {
        $('.slider-nav').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            arrows: false,
            dots: true,
            focusOnSelect: true
        });
    }

    function sliderthree() {
        $('.js-slider').on('init', function () {
            $('.gallery-slider-js').addClass('active');
        });

        $('.js-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true
        });
    }

    function sliderfour() {
        $('.js-slider-two').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        });
    }

    function slider() {
        $('a[data-slide]').click(function (e) {
            e.preventDefault();
            var slideno = $(this).data('slide');
            $('.slider-nav').slick('slickGoTo', slideno - 1);
            $('.js-slider-link').removeClass('active');
            $(this).toggleClass('active');
        });

        $(".slider").on("beforeChange", function (event, slick, currentSlide, nextSlide) {
            $('a[data-slide]').removeClass('active');
            $('a[data-slide="' + (nextSlide + 1) + '"]').addClass('active');
        });

        var allAnimations = {};

        function animationSVG() {
            var anim = $('[data-animate]');

            anim.each(function () {
                var currentSlide = $(this).parents('.js-slider-content').data('idx') - 1;
                allAnimations[currentSlide] = lottie.loadAnimation({
                    container: $(this)[0],
                    renderer: 'svg',
                    path: $(this).data('animation-path'),
                    name: $(this).data('name'),
                    loop: false,
                    autoplay: false,
                    rendererSettings: {
                        scaleMode: 'noScale',
                        clearCanvas: true,
                        progressiveLoad: false,
                        hideOnTransparent: true
                    }
                });
            });
        }

        animationSVG();

        $(".slider").on("afterChange", function (event, slick, currentSlide) {
            if (currentSlide in allAnimations) {
                allAnimations[currentSlide].play();
            }
        });
    }




    function verticalslider($selector) {
        $selector.on('click', function (e) {
            var parent = $(this).parents('.js-vertical-box');
            $(this).parent(".js-list").find('active').removeClass('active');
            $(this).addClass('active');
            e.preventDefault();

            parent.find('.js-vertical-slider .js-slider-content').removeClass('active');

            var target = $('[data-id="' + $(this).data('slide') + '"]', parent);
            if (target.length) {
                target.addClass('active');

                var el = target.find('.js-scroll-to-one');
                el.trigger('appear', [el]);
            }
        });
    }


    if ($('.js-counter').hasClass('active')) {
        $('.js-counter').each(function () {
            return false;
        });
    }

    $('.js-cp-time').html(new Date().getFullYear());


    $(function () {
        var $appeared = $('#appeared');
        $('.js-scroll-to-one').appear();
        $('.js-animate').appear();
        $('.js-scroll-to').appear();
        $('.js-scroll-to-two').appear();
        $('.js-scroll-to-three').appear();
        $('.js-side').appear();
        $('.js-arrow').appear();
        $('.js-login').appear();

        $(document.body).on('appear', '.js-scroll-to-one', function (e, $affected) {
            if ($(this).parents('.js-slider-content').hasClass('active')) {
                TweenMax.to(this, 2, {
                    y: 0,
                    opacity: 1
                });
            }

            var affected = $affected.filter(function (i, v) {
                return $(v).parents('.js-slider-content').hasClass('active')
            });
            $('.js-counter', affected).each(function () {
                var $this = $(this),
                    countTo = $this.attr('data-count');
                $({
                    countNum: $this.text()
                }).animate({
                    countNum: countTo
                }, {
                    duration: 2000,
                    easing: 'linear',
                    step: function () {
                        $this.text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $this.text(this.countNum);
                    }
                });
            });
        });
        $(document.body).on('appear', '.js-animate', function (f, $affected) {
            TweenMax.to('.js-animate', 1.5, {
                x: 0,
                opacity: 1
            });
        });
        $(document.body).on('appear', '.js-side', function (g, $affected) {
            TweenMax.to('.js-side', 1.5, {
                y: 0,
                opacity: 1
            });
        });
        $(document.body).on('appear', '.js-arrow', function (h, $affected) {
            TweenMax.to('.js-arrow', 1.5, {
                delay: 1.5,
                opacity: 1
            });
        });

        $(document.body).on('appear', '.js-scroll-to', function () {
            TweenMax.to('.js-scroll-to', 1.5, {
                delay: 0,
                opacity: 1,
                y: 0,
            });
        });

        $(document.body).on('appear', '.js-scroll-to-two', function () {
            TweenMax.to('.js-scroll-to-two', 1, {
                delay: 0,
                opacity: 1,
                y: 0,
            });
        });

        $(document.body).on('appear', '.js-scroll-to-three', function () {
            TweenMax.to('.js-scroll-to-three', 1, {
                delay: 0,
                opacity: 1,
                y: 0,
            });
        });

        $(document.body).on('appear', '.js-login', function () {
            TweenMax.staggerTo(".js-animate-sequence", 0.8, {
                opacity: 1,
                y: 0,
                onCompleteParams: ["{self}"]
            }, 0.3);
        });
    });

    TweenMax.to('.js-appear', 0.6, {
        y: 0,
        opacity: 1,
        delay: 0
    });

    $(".js-file-button").click(function () {
        var element = $(this).closest('.js-box-content').find('.js-file-list');
        if (element.is(':visible')) {
            element.fadeOut();
        } else {
            element.fadeIn();
        }
        // $('.js-file-list').fadeOut();
        // element.fadeIn('slow');
    });


    $(document).mouseup(function (i) {
        if (!$('.js-file-button').is(i.target) && $('.js-box-content').has(i.target).length === 0) {
            $('.js-file-list').fadeOut('slow');
        }
    });

    function stickyHeader() {
        window.onscroll = function () {
            myFunction();
        };

        var header = document.getElementById("js-sticky");
        var sticky = header.offsetTop;

        function myFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }
    }

    function offsettop() {
        $('.js-scroll').click(function (a) {
            $(this).removeClass('active');
            var datascroll = $(this).data('scroll');
            a.preventDefault();
            $('html, body').animate({
                scrollTop: $('#' + datascroll).offset().top - 100
            }, 1000);
        });
    }

    function formMessage() {
        $('.js-message-close').click(function () {
            $('.js-success').removeClass('active');
            $('.js-error').removeClass('active');
        });
    }

    function isScrolledIntoView(elem) {
        return $(window).scrollTop() >= ($(elem).position().top - $(window).height() / 2);
    }

    $(window).scroll(function () {
        $('.section[data-appear]').each(function () {
            var dataAppear = $(this).data('appear');
            if (isScrolledIntoView(this) === true) {
                $('.main-header__list-item.active').removeClass('active');
                $('.main-header__list-item.current').removeClass('current');
                $('#' + dataAppear).parent().addClass('current');
            }
        });
    });


    function spritespin() {
        var frames = SpriteSpin.sourceArray('', {
            frame: [1, framesNumber],
            digits: 3
        });

        var spin = $("#spritespin");
        var slide = $("#slider");

        spin.spritespin({
            source: spriteSource,
            animate: false,
            onLoad: function () {
                slide.slider({
                    min: 0,
                    max: frames.length - 1,
                    slide: function (e, ui) {
                        var api = spin.spritespin('api');
                        api.stopAnimation();
                        api.updateFrame(ui.value);
                    }
                });
            },
            onFrame: function (e, data) {
                slide.slider('value', data.frame);
            }
        });
    }

    function video() {
        $(".js-video-open-btn").click(function () {
            $(this).addClass('active');
            $('.js-video-popup').fadeIn('slow');
            $('.js-site').addClass('active');
            $('.js-video-box').addClass('active');
            if ($('.js-video-content').find('iframe').length !== 0) {
                $("#video")[0].src += "&autoplay=1";
            } else {
                $('.js-video').get(0).play();
                $('.js-video').attr('controls', true);
            }
            $('.js-slider').addClass('open');
        });

        $(".js-video-open-btn-two").click(function () {
            $(this).addClass('active');
            $('.js-video-popup').fadeIn('slow');
            $('.js-video-box').addClass('active');
            if ($('.js-video-content').find('iframe').length !== 0) {
                $("#video")[0].src += "&autoplay=1";
            } else {
                $('.js-video').get(0).play();
                $('.js-video').attr('controls', true);
            }
        });

        $(".js-video")[0].addEventListener('webkitendfullscreen', function () {
            $('.js-video-popup').fadeOut('slow');
            $('.js-site').removeClass('active');
            $('.js-slider').removeClass('open');
            $('.js-video').each(function () {
                // this.currentTime = 0;
                this.pause();
            });
        }, true);


        $(".js-video-close-btn").click(function () {
            $('.js-video-popup').fadeOut('slow');
            $('.js-site').removeClass('active');
            $('.js-slider').removeClass('open');
            $('.js-video').each(function () {
                // this.currentTime = 0;
                this.pause();
            });
        });

        jQuery(document).on('keyup', function (evt) {
            if (evt.keyCode == 27) {
                $('.js-video-popup').fadeOut('slow');
                $('.js-site').removeClass('active');
                $('.js-slider').removeClass('open');
                $('.js-video').each(function () {
                    // this.currentTime = 0;
                    this.pause();
                });
            }
        });
    }

    $('.js-to-top-btn').hide();

    $(window).scroll(function () {
        if ($(this).scrollTop() >= 500) {
            $('.js-to-top-btn').fadeIn(400);
        } else {
            $('.js-to-top-btn').fadeOut(400);
        }

        if ($(this).scrollTop() + $(window).height() == $(document).height()) {
            $('.js-to-top-btn').css('bottom', 80);
        } else {
            $('.js-to-top-btn').css('bottom', 30);
        }

        var distanceFromBottom = Math.floor($(document).height() - $(document).scrollTop() - $(window).height());

        if (distanceFromBottom < 460) {
            $('.js-to-top-btn').addClass('active');
        } else {
            $('.js-to-top-btn').removeClass('active');
        }

        if ($(this).scrollTop() >= 500) {
            $('.js-list-appear').addClass('active');
            $('.main-content__two').addClass('active');
        }
    });

    $('.js-to-top-btn').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });

    $(document).on("click", '.js-burger', function () {
        $('.js-burger').toggleClass('active');
        $('.js-main-header').toggleClass('active');
        $('body').toggleClass('body-active');
        $('.js-navigation').fadeToggle(350);
        $('.js-nav-list').toggleClass('is-open');
        $('.js-popup').toggleClass('active');
        $('.js-list-appear').toggleClass('hidden');
        $('.main-header__btn').toggleClass('hidden');
    });




    $(document).on("click", '.js-popup-btn', function () {
        var thisUrl = $(this).data('url');
        $('.js-popup-btn').removeClass('active');
        $(this).toggleClass('active');
        $('.js-popup').show();
        $('.js-popup').addClass('active');
        $('body').addClass('active');
        $.ajax({
            url: thisUrl,
            dataType: "html",
            success: function (data) {
                $(".js-load .content").html(data);
                $('.js-slider-three').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                });

                $('.js-gallery-close-btn').click(function () {
                    $('body').removeClass('body-active');
                    $('body').removeClass('active');
                    $('.js-popup').hide();
                    $('.js-language-change').fadeOut();
                });


            }
        });
    });

    function loadRow() {

        var textTab = $('.js-row'),
            textTabContainer = $('.js-row-container');

        if (textTab.length) {

            textTab.off('click').on('click', function () {
                var textRow = $(this).parent(),
                    textContent = $(this).parent().find('.js-row-item');

                textTabContainer.find('.js-row-item').not(textContent).stop().slideUp('slow');
                textTabContainer.find('.js-row-content').not(textRow).removeClass('active');

                textContent.stop().slideToggle('slow', function () {
                    textRow.toggleClass('active', textContent.is(':visible'));
                });
            });
        }


        $('.js-load-row').click(function (n) {
            var dataRow = $(this).data('row');
            $(this).addClass('active');
            $('.js-load-row').removeClass('active');
            $('.js-row__box').removeClass('active');
            $('#' + dataRow).addClass('active');
        });

    }

    $('.js-load-address').click(function () {
        var UrlTwo = $(this).data('ajax-url');
        $('.js-load-address').removeClass('active');
        $(this).toggleClass('active');
        $.ajax({
            url: UrlTwo,
            dataType: "html",
            success: function (data) {
                $(".js-list-container").html(data);
                $('.js-link-map').on('click', function () {

                    var myLatLng = {
                        lat: parseFloat($(this).data('lat')),
                        lng: parseFloat($(this).data('lng'))
                    };

                    var mapIconUrl = $('.js-initial-map').data('icon-url');

                    var markerUrl = $(this).parent().find('.js-map-url').data('url');
                    var map = new google.maps.Map(document.getElementById('js-map'), {
                        zoom: 11,
                        center: myLatLng
                    });
                    var marker = new google.maps.Marker({
                        position: myLatLng,
                        map: map,
                        title: 'Open in Google Maps',
                        icon: mapIconUrl,
                        url: markerUrl,
                    });
                    map.setCenter(myLatLng);
                    marker.setPosition(myLatLng);
                    google.maps.event.addListener(marker, 'click', function () {
                        window.open(marker.url);
                    });
                });
            }
        });
    });

    function timeline() {

        $('.js-timeline').slick({
            vertical: true,
            verticalSwiping: true,
            swipe: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.js-timeline-nav',
            draggable: false,
        });

        $('.js-slider-text').slick({
            dots: true,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: false,
            horizontal: true,
            horizontalSwiping: true,
            slidesToShow: 1,
            slidesToScroll: 1,

        });

        $('.js-timeline-nav').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.js-timeline',
            dots: true,
            focusOnSelect: true,
            centerMode: true,
            dots: false,
            draggable: false,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });


        $('.js-nav-item').click(function (g) {
            g.preventDefault();
            $('.js-timeline-nav-show').removeClass('active');
        });

        $('.js-timeline-nav-close').click(function () {
            $('.js-timeline-nav-show').removeClass('active');
        });

        $('.js-slider-btn').click(function () {
            $('.js-timeline-nav-show').addClass('active');
        });


        $(document).keydown(function (q) {
            if (q.which == 38) {
                $('.js-timeline').slick('slickPrev');
            }
            if (q.which == 40) {
                $('.js-timeline').slick('slickNext');
            }
        });

        $(document).keydown(function (p) {
            if (p.which == 27) {
                $('.js-timeline-nav-show').removeClass('active');
            }
        });


        $('.js-timeline').on('wheel', (function (r) {
            var body = $(this);
            if (!body.hasClass("animating")) {
                body.addClass("animating");
                if (r.originalEvent.deltaY < 0) {
                    $(this).slick('slickPrev');
                } else {
                    $(this).slick('slickNext');
                }
                setTimeout(function () {
                    body.removeClass("animating");
                }, 1500);
            }
        }));
    }

    function stickySidebar() {
        var sidebar = new StickySidebar('.sidebar', {
            topSpacing: 600,
            bottomSpacing: 20,
            minWidth: 1200,
            containerSelector: '.js-text',
            innerWrapperSelector: '.js-sidebar'
        });
    }


    function verticalSliderTwo() {
        var items = $(".js-sly");
        items.each(function () {
            var i = new Sly($(this)[0], {
                horizontal: 1,
                itemNav: "basic",
                smart: 1,
                activateOn: "click",
                mouseDragging: 1,
                touchDragging: 1,
                releaseSwing: 1,
                startAt: 0,
                scrollBy: 1,
                scrollBar: $(".scrollbar"),
                activatePageOn: "click",
                speed: 300,
                elasticBounds: 1,
                dragHandle: 1,
                dynamicHandle: 1,
                clickBar: 1
            });
            i.init();
        });
    }

    $(".js-count").each(function (i, elem) {
        $(elem).prepend($("<span class=" + "product-accents__count" + ">" + (i + 1) + "." + " " + "</span>"));
    });

    $(".js-arrow").each(function (i, elem) {
        $(elem).prepend($("<span class=" + "product-accents__count-two" + ">" + (i + 1) + "</span>"));
    });


    function languageSelector() {
        $(".js-language-change").click(function (e) {
            $('.js-lang-choice').fadeToggle();
        });
    }



    // $(".js-row-form-container").html(data);

    $.validator.addClassRules('customphone', {
        customphone: true
    });

    $.validator.addMethod('customphone', function (value, element) {
        return this.optional(element) ||
            /^\+?\s*?(?:\d+\s*?(?:\d+\s*?(?:\d+\s*)))(?:[\d|\s]*?)$/
            .test(value);
    }, $('#phone').attr('data-phone-error'));

    $('.js-form').validate({
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.js-container')
                .last());
        },
        rules: {
            phone: 'customphone'
        }
    });

    $.validator.setDefaults({
        ignore: [],
    });

    $('.js-checkbox').change(function () {
        var groupError = $('.js-group').attr(
            'data-group-error');
        var closest = $(this).closest('.js-error-group').find(
            ".js-group");
        if (closest.find('.js-checkbox').is(':checked')) {
            closest.next('#space').remove();
        } else {
            if (closest.next('#space').length > 0) {
                closest.next('#space').remove();
            }
            closest.after(
                '<div id="space" class="mb-15"><span class="error">' +
                groupError + '</span></div>');
        }
    });

    $.validator.addMethod("checkone", function (e) {
        var groupError = $('.js-group').attr(
            'data-group-error');

        if ($(".js-checkbox").is(":checked")) {
            // $('.js-group').remove('#space');
            return true;
        }
        if ($('#space').length < 1) {
            $('.js-group').after(
                '<div id="space" class="mb-15"><span class="error">' +
                groupError + '</span></div>');
            return false;
        }
    });
    window.scrollBy(0, 1);

    function tooltip() {
        $.each($('.js-tooltip'), function (i, element) {
            tippy(element, {
                theme: 'light',
                content: $(element).find('.js-tooltip-content').html(),
                allowHTML: true,
                interactive: true,
            });
        });
    }

});


function datepicker() {

    $.each($('.js-datepicker'), function () {

        TinyDatePicker(this, {
            mode: 'dp-below',
        });
    });
}